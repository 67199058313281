// import React, { useCallback, useEffect, useRef } from 'react';
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import Paper from "@material-ui/core/Paper";
// import { withAppContext } from "../../contextApi/Auth";
// import {
//     Box,
//     Card,
//     CardContent,
//     CardHeader,
//     FilledInput,
//     FormControl,
//     FormGroup,
//     FormLabel,
//     Grid,
//     makeStyles,
//     Typography,
//     useTheme
// } from "@material-ui/core";
// import axios from "axios";
// import { BACKEND_URL } from "../../env";
// import { toast } from "react-toastify";
// import Loader from "../../components/Loader/loader";
// import { useState } from 'react';
// import { centerCrop, makeAspectCrop, ReactCrop } from 'react-image-crop';
// import 'react-image-crop/src/ReactCrop.scss'
// import AvatarEditor from 'react-avatar-editor';
// // import AvatarEditor from 'react-avatar-editor';

// function rand() {
//     return Math.round(Math.random() * 20) - 10;
// }
// const TO_RADIANS = Math.PI / 180;

// function getModalStyle() {
//     const top = 50 + rand();
//     const left = 50 + rand();

//     return {
//         top: `${top}%`,
//         left: `${left}%`,
//         transform: `translate(-${top}%, -${left}%)`,
//     };
// }

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         position: 'absolute',
//         width: 400,
//         backgroundColor: theme.palette.background.paper,
//         border: '2px solid #000',
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(2, 4, 3),
//     },
// }));
// function centerAspectCrop(
//     mediaWidth,
//     mediaHeight,
//     aspect,
// ) {
//     return centerCrop(
//         makeAspectCrop(
//             {
//                 // unit: "px"
//                 width: 300,
//             },
//             aspect,
//             mediaWidth,
//             mediaHeight,
//         ),
//         mediaWidth,
//         mediaHeight,
//     )
// }
// export default function CropImageModal({ show, onClose, setCropImage
//     , cropImage, aspect }) {
//     const classes = useStyles();
//     // getModalStyle is not a pure function, we roll the style only on the first render
//     const [crop, setCrop] = useState({ unit: "%", width: 300, aspect: 1 });
//     const [completedCrop, setCompletedCrop] = useState(null);
//     const [croppedImageUrl, setCroppedImageUrl] = useState("");
//     const [modalStyle] = React.useState(getModalStyle);
//     const [Loading, setLoading] = useState(false)
//     const imgRef = useRef(null);
//     const onLoad = (img) => {
//         imgRef.current = img;
//         console.log(img, "image")
//         console.log(imgRef, "imd ref")
//     }

//     function setCanvasImage(image, canvas, crop) {
//         // const canvas = document.createElement("canvas");
//         if (!crop || !image) {
//             console.log("EEEEEERRRR");
//             return;
//         }

//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         const ctx = canvas.getContext("2d");
//         // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
//         const pixelRatio = window.devicePixelRatio;

//         canvas.width = crop.width * pixelRatio * scaleX;
//         canvas.height = crop.height * pixelRatio * scaleY;

//         // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
//         ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
//         ctx.imageSmoothingQuality = "high";

//         // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
//         ctx.drawImage(
//             image,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width * scaleX,
//             crop.height * scaleY
//         );
//     }
//     const EditorRef = useRef(null);
//     const showCroppedImage = async () => {
//         if (EditorRef.current) {
//             console.log(EditorRef.current);
//             const img = EditorRef.current.getImage().toDataURL();
//             return img;
//         }
//     };
//     const makeClientCrop = async (crop) => {
//         if ((cropImage[0], crop.width && crop.height)) {
//             const croppedImg = await getCroppedImg(cropImage[0], crop, "newFile.jpeg");
//             setCroppedImageUrl(croppedImg);
//         }
//     };
//     const Cropper = ({ imgURL }) => {


//     }
//     const getCroppedImg = (sourceImage, crop, fileName) => {
//         const canvas = document.createElement("canvas");
//         const scaleX = sourceImage.naturalWidth / sourceImage.width;
//         const scaleY = sourceImage.naturalHeight / sourceImage.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;
//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(
//             sourceImage,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width,
//             crop.height
//         );
//         try {
//             return new Promise((resolve) => {
//                 canvas.toBlob((file) => {
//                     resolve(URL.createObjectURL(file));
//                 }, "image/jpeg");
//             });
//         } catch (error) {
//             console.log(error);
//             return null;
//         }
//     };

//     return (
//         <Dialog open={show} onClose={() => onClose()} aria-labelledby="draggable-dialog-title">
//             <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
//                 Crop Image
//             </DialogTitle>
//             <DialogContent>
//                 <Card classes={{ root: classes.cardRoot }}>
//                     <Box component={CardContent} classes={{ root: classes.ptMd4 }} paddingTop="0!important">
//                         <ReactCrop
//                             src={cropImage[0]}
//                             crop={crop}
//                             ruleOfThirds
//                             onImageLoaded={onLoad}
//                             onComplete={(crop) => makeClientCrop(crop)}
//                             onChange={(cropData) => setCrop(cropData)}
//                         >
//                             <img src={cropImage[0]} />
//                         </ReactCrop>
//                         {/* <ReactCrop
//                             src={cropImage[0]}
//                             crop={crop}
//                             ref={EditorRef}
//                             aspect={16 / 9}
//                             // onImageLoaded={(e) => onLoad(e)}
//                             onChange={c => setCrop(c)}
//                             onComplete={(c) => setCompletedCrop(c)}
//                         //  onComplete={async (c) => {
//                         //     let image = cropImage[0]
//                         //     console.log(image, "IMAGE");
//                         //     let img = await getCroppedImg(image, c)
//                         //     console.log(img, "imgimgimgimg");

//                         // }}
//                         >
//                             <img src={cropImage[0]} onLoad={onLoad}
//                             />
//                         </ReactCrop> */}
//                         {/* <canvas
//                             ref={previewCanvasRef}
//                             // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
//                             style={{
//                                 width: Math.round(completedCrop?.width ?? 0),
//                                 height: Math.round(completedCrop?.height ?? 0)
//                             }}
//                         /> */}
//                         {/* <div className="w-full h-full flex flex-col items-center justify-center">
//                             <AvatarEditor
//                                 ref={EditorRef}
//                                 image={cropImage[0]}
//                                 width={250}
//                                 height={250}
//                                 border={0}
//                                 scale={1}
//                                 // color={[255, 255, 255, 0.6]}
//                             />
//                         </div> */}

//                     </Box>
//                 </Card>
//             </DialogContent>
//             <DialogActions>
//                 <Button autoFocus onClick={() => onClose()}>
//                     Cancel
//                 </Button>
//                 <Button onClick={() => showCroppedImage()}>
//                     {Loading ? <Loader cumtomStyle={{ position: "unset", }} /> : "Confirm"}
//                 </Button>
//             </DialogActions>
//         </Dialog >
//     );
// }

import React, { useCallback, useEffect, useRef } from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { withAppContext } from "../../contextApi/Auth";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    FilledInput,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    makeStyles,
    Typography,
    useTheme
} from "@material-ui/core";
import axios from "axios";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/loader";
import { useState } from 'react';
// import { centerCrop, makeAspectCrop, ReactCrop } from 'react-image-crop';
// import 'react-image-crop/src/ReactCrop.scss'
// import AvatarEditor from 'react-avatar-editor';
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
// import AvatarEditor from 'react-avatar-editor';


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                // unit: "px"
                width: 300,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
export default function CropImageModal({ show, onClose, setCropImage
    , cropImage, aspect, saveImgFn }) {
    const classes = useStyles();
    const cropperRef = useRef(null);
    const [croppedImg, setCroppedImg] = useState("");
    const [Loading, setLoading] = useState(false)
    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCroppedImg(cropper.getCroppedCanvas().toDataURL());
    };
    return (
        <Dialog open={show} onClose={() => onClose()} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                Crop Image
            </DialogTitle>
            <DialogContent>
                <Card classes={{ root: classes.cardRoot }}>
                    <Box component={CardContent} classes={{ root: classes.ptMd4 }} paddingTop="0!important">
                        <Cropper
                            src={cropImage[0]}
                            // style={{ height: 500, width: "732px" }}
                            initialAspectRatio={aspect}
                            guides={true}
                            crop={onCrop}
                            ref={cropperRef}
                            // viewMode={1}
                            // minCropBoxHeight={10}
                            // minCropBoxWidth={10}
                            // background={false}
                            // responsive={true}
                            // autoCropArea=sq{1}
                            aspectRatio={aspect}
                        // checkOrientation={false}
                        />
                        {/* <img src={croppedImg} /> */}
                    </Box>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button onClick={() => {
                    setLoading(true)
                    saveImgFn(croppedImg)
                    onClose()
                    setCroppedImg('')
                    setLoading(false)
                }}>
                    {Loading ? <Loader cumtomStyle={{ position: "unset", }} /> : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog >
    );
}
