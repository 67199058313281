import React from "react";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {
	const token = localStorage.getItem("Token");
	if (token) {
		return <Redirect to="/admin/index" />;
	} else {
		return <Redirect to="/auth/login" />;
	}
}

export default PrivateRoute;
