import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import AddQuantity from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Decrease from "@material-ui/icons/Minimize";
// core components
import UserHeader from "../../components/Headers/UserHeader.js";

import componentStyles from "../../assets/theme/views/admin/profile.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import { BACKEND_URL } from "../../env.js";
import { withPurchaseContext } from "../../contextApi/Purchase/index.js";

const useStyles = makeStyles(componentStyles);

function CreatePurchase({
  Products,
  selected,
  GetAllPurchaseProducts,
  setSelected,
  setQuantity,
  clearSelected,
  removeSelected,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  useEffect(() => {
    GetAllPurchaseProducts();
  }, []);

  const handleCreateSubmit = (e) => {
    const Obj = {
      Products: selected,
    };

    console.log(localStorage.getItem("Token"));
    const config = {
      method: "post",
      url: `${BACKEND_URL}/CreatePurchase`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((e) => {
        // history.push("/admin/index");
        clearSelected();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignitem="center"
                    justifycontent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Selected Products
                      </Box>
                    </Grid>
                  </Grid>
                }
                action={
                  <Button
                    variant="contained"
                    size="small"
                    classes={{ root: classes.buttonRootDark }}
                    onClick={() => handleCreateSubmit()}
                  >
                    Create
                  </Button>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  User Information
                </Box>
                <div className={classes.plLg4}>
                  {selected.map((product) => {
                    return (
                      <Card
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                          alignContent: "space-between",
                          justifyContent: "space-between",
                          padding: "20px",
                          width: "100%",
                        }}
                        key={product.id}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "80%",
                          }}
                        >
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography component="div" variant="h5">
                              {product.name}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                            >
                              {product.price}
                            </Typography>
                          </CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pl: 1,
                              pb: 1,
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setQuantity(
                                  product.id,
                                  Number(product.Quantity) - 1
                                );
                              }}
                            >
                              <Decrease
                                style={{ width: "25px", height: "25px" }}
                              />
                            </IconButton>
                            <IconButton>{Number(product.Quantity)}</IconButton>
                            <IconButton
                              onClick={() => {
                                setQuantity(
                                  product.id,
                                  Number(product.Quantity) + 1
                                );
                              }}
                            >
                              <AddQuantity
                                style={{ width: "25px", height: "25px" }}
                              />
                            </IconButton>
                          </Box>
                          <IconButton
                            onClick={() => {
                              removeSelected(product.id);
                            }}
                          >
                            <Delete style={{ width: "25px", height: "25px" }} />
                          </IconButton>
                        </Box>
                        <CardMedia
                          component="img"
                          style={{ width: "100px" }}
                          image={`${BACKEND_URL}/GetImage/${product?.Images?.[0]?.filename}`}
                          alt={product.name}
                        />
                      </Card>
                    );
                  })}
                </div>
                <Button
                  variant="contained"
                  size="small"
                  classes={{ root: classes.buttonRootDark }}
                  onClick={() => handleCreateSubmit()}
                >
                  Create
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            xl={4}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.order1 + " " + classes.marginBottomXl0 }}
          >
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignitem="center"
                    justifycontent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        All Products
                      </Box>
                    </Grid>
                  </Grid>
                }
                action={
                  <Button
                    variant="contained"
                    size="small"
                    classes={{ root: classes.buttonRootDark }}
                    onClick={() => handleCreateSubmit()}
                  >
                    Create
                  </Button>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <Box
                style={{ height: "50vh", overflowY: "scroll", width: "100%" }}
              >
                {Products.map((product) => {
                  return (
                    <Card
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        alignContent: "space-between",
                        justifyContent: "space-between",
                        padding: "20px",
                        width: "100%",
                      }}
                      key={product.id}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "80%",
                        }}
                      >
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography component="div" variant="h5">
                            {product.name}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                          >
                            {product.price}
                          </Typography>
                        </CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            pl: 1,
                            pb: 1,
                          }}
                          onClick={() => {
                            setSelected(product.id);
                          }}
                        >
                          <Button>Add to Cart</Button>
                        </Box>
                      </Box>
                      <CardMedia
                        component="img"
                        style={{ width: "100px" }}
                        image={`${BACKEND_URL}/GetImage/${product?.Images?.[0]?.filename}`}
                        alt={product.name}
                      />
                    </Card>
                  );
                })}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAppContext(withPurchaseContext(CreatePurchase));
