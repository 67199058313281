export default function PurchaseReducer(state, action) {
	switch (action.type) {
		case "SET_SELECTED":
			const item = state.Products.filter((product) => product.id === action.payload.id);
			const Quantity = {
				Quantity: 1
			};
			const itemAddQuantity = item.map((a) => Object.assign(a, Quantity));
			const SelectedItems = state.selected;
			const SetSelected = SelectedItems.concat(itemAddQuantity);

			const isSelected = state.selected.filter((a) => a.id == action.payload.id);
			if (isSelected.length >= 1) {
				return {
					...state
				};
			} else {
				return {
					...state,
					selected: SetSelected
				};
			}

		case "SET_QUANTITY":
			const updatedQuantity = action.payload.Quantity;
			const itemId = action.payload.id;

			const updatedQuantities = state.selected.filter((select) => {
				if (select.id === itemId) {
					select.Quantity = updatedQuantity;
					return select;
				}
				return select;
			});
			return {
				...state,
				selected: updatedQuantities
			};

		case "REMOVE_SELECTED":
			return {
				...state,
				selected: state.selected.filter((select) => select.id !== action.payload)
			};

		case "CLEAR_SELECTED":
			return {
				...state,
				selected: []
			};
		default:
			return state;
	}
}
