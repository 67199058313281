import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";

import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import { Table, Avatar, Button, Input } from "antd";

// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import CreateColor from "./CreateColor";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { SearchOutlined } from "@material-ui/icons";

const useStyles = makeStyles(componentStyles);

const Color = ({ getColor, deleteColor, ...props }) => {
  const classes = useStyles();

  const [openModel, setOpenModel] = useState(false);

  const [UpdateData, setUpdateData] = useState("");
  const [colors, setColors] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleClickOpen = () => {
    setOpenModel(true);
  };

  const handleClose = () => {
    setUpdateData("");
    setOpenModel(false);
  };

  useEffect(() => {
    if (props.colorData) {
      setColors(props.colorData);
    }
    console.log(props.colorData);
  }, [props.colorData]);

  useEffect(() => {
    getColor();
  }, []);

  useEffect(() => {
    if (colors) {
      console.log(colors);
    }
  }, [colors]);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          id={`search-input-${dataIndex}`}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? <span>{text}</span> : text,
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      sorter: (a, b) =>
        a.color.localeCompare(b.color, undefined, {
          sensitivity: "base",
        }),
      ...getColumnSearchProps("color"),
      render: (color) => <span>{color}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setOpenModel(true);
              setUpdateData(record);
            }}
          />

          <Button
            icon={<DeleteOutlined style={{ color: "red" }} />}
            danger
            style={{ marginLeft: 8 }}
          />
        </span>
      ),
    },
  ];
  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Available Color"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button variant="outlined" onClick={handleClickOpen}>
                Create Color
              </Button>
            }
          ></CardHeader>

          <Table
            columns={columns}
            dataSource={colors}
            rowKey="id"
            style={{ paddingRight: 10, paddingLeft: 10 }}
            className="userTable"
          />
        </Card>
        <CreateColor
          open={openModel}
          handleClose={handleClose}
          UpdateData={UpdateData}
          setUpdateData={setUpdateData}
        />
      </Container>
    </>
  );
};

export default withAppContext(Color);
