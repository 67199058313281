import React, { useEffect, useState } from "react";

// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import Add from "@material-ui/icons/Add";
import "sweetalert2/src/sweetalert2.scss";
// core components
import Header from "../../components/Headers/Header.js";

import { Button, IconButton, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { BACKEND_URL } from "../../env.js";
import { Rating } from "@material-ui/lab";
import Swal from "sweetalert2";
import axios from "axios";

const useStyles = makeStyles(componentStyles);

const RatingTable = ({ AllRatings, GetAllRatings }) => {
  const classes = useStyles();
  const history = useHistory();
  const [pageNo, setPageNo] = useState(1);
  const [rating, setRating] = useState([]);
  const [pagination, setPagination] = useState(true);
  useEffect(() => {
    GetAllRatings();
  }, []);
  useEffect(() => {
    setPageNo(1);
    setPagination(true);
    setRating(AllRatings);
  }, [AllRatings]);
  // const getMoreProducts = () => {
  // 	let pgno = pageNo + 1
  // 	axios
  // 		.get(`${BACKEND_URL}/GetAllProducts?pageLength=${10}&pageNo=${pgno}`, {
  // 			headers: {
  // 				token: localStorage.getItem("Token"),
  // 			},
  // 		})
  // 		.then((res) => {
  // 			setProducts(res.data);
  // 			setPageNo(pgno)
  // 			if (res.data < 9) {
  // 				setTimeout(() => {
  // 					setPagination(false)
  // 				}, 1000)
  // 			}
  // 		})
  // 		.catch((res) => console.log(res));
  // }
  // console.log("AllProducts",AllProducts)
  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Products"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0 !important",
              variant: "h3",
            }}
            action={
              <IconButton
                aria-label="settings"
                style={{ background: "blue" }}
                onClick={() => history.push("/admin/addProduct/New")}
              >
                <Add style={{ width: "30px", height: "30px", color: "#FFF" }} />
              </IconButton>
            }
          ></CardHeader>
          {rating?.length >= 1 ? (
            <TableContainer>
              <Box
                component={Table}
                alignItems="center"
                marginBottom="0!important"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      {/* Price */}
                      Name
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Title
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Rating
                    </TableCell>

                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      hide
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rating?.map((product) => {
                    console.log("products------> ", product);
                    return (
                      <TableRow key={product?.id}>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          component="th"
                          variant="head"
                          scope="row"
                        >
                          <Box alignItems="center" display="flex">
                            <Box
                              component={Avatar}
                              marginRight="1rem"
                              alt={product?.name}
                              src={
                                product?.images
                                  ? `${BACKEND_URL}/GetImage/${product?.images.filename}`
                                  : require("../../assets/img/icons/common/No Image.png")
                                      .default
                              }
                            />
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize=".875rem" component="span">
                                {product?.name || "-"}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          Rs. {product?.review?.name}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box paddingTop=".35rem" paddingBottom=".35rem">
                            {product?.review?.email || "-"}
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box display="flex" alignItems="center">
                            <Box component="span" marginRight=".5rem">
                              {product?.review?.title || "-"}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box display="flex" alignItems="center">
                            <Tooltip
                              title={product?.review?.description}
                              placement="top"
                              width={400}
                            >
                              <Box
                                component="span"
                                marginRight=".5rem"
                                width={400}
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                              >
                                {product?.review?.description || "-"}
                              </Box>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box display="flex" alignItems="center">
                            <Box component="span" marginRight=".5rem">
                              <Rating
                                name="read-only"
                                value={product?.review?.rating || 0}
                                readOnly
                              />
                            </Box>
                          </Box>
                        </TableCell>{" "}
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box display="flex" alignItems="center">
                            <Box
                              component="span"
                              marginRight=".5rem"
                              whiteSpace={""}
                              style={{}}
                            >
                              {product?.review?.isHide || "false"}
                            </Box>
                          </Box>
                        </TableCell>{" "}
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {product?.review?.isHide !== "true" ? (
                            <Box display="flex" alignItems="center">
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You want to hide this Review",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Hide Review!",
                                    preConfirm: () => {
                                      axios
                                        .post(
                                          `${BACKEND_URL}/UpdateRating/${product?.review?.id}`,
                                          {
                                            isHide: "true",
                                          },
                                          {
                                            headers: {
                                              token:
                                                localStorage.getItem("Token"),
                                            },
                                          }
                                        )
                                        .then(() => {
                                          GetAllRatings();
                                        })
                                        .catch((res) => console.log(res));
                                    },
                                  })
                                }
                              >
                                Hide Review
                              </Button>
                            </Box>
                          ) : null}
                        </TableCell>{" "}
                      </TableRow>
                    );
                  })}
                  {/* </InfiniteScroll> */}
                </TableBody>
              </Box>
            </TableContainer>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>No one rated on any product</h1>
            </div>
          )}
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination color="primary" variant="outlined" />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default withAppContext(RatingTable);
