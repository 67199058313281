import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Space, Table } from "antd";
import Header from "../../components/Headers/Header";
import {
  Box,
  Card,
  CardHeader,
  Container,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import Highlighter from "react-highlight-words";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { SearchOutlined } from "@material-ui/icons";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { BACKEND_URL } from "../../env.js";
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles(componentStyles);
const SendMailToCustomers = ({
  GetAllMultipleProducts,
  AllMultipleProducts,
}) => {
  const [searchText, setSearchText] = useState("");
  const [listingData, setListingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectListingData, setSelectListingData] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectListingData(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  useEffect(() => {
    let arr = [];
    AllMultipleProducts.map((a, i) => {
      arr.push({
        key: a?.id,
        name: a?.product?.name,
        image: a?.Images?.filter((item) => item?.itemColor === a?.color)[0]
          .filename,
        quanitty: a?.QuantityAvailable,
        color: a?.color,
        size: a?.size,
        price: a?.price,
      });
    });
    setListingData(arr);
  }, [AllMultipleProducts]);
  useEffect(() => {
    GetAllMultipleProducts();
  }, []);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={
              <SearchOutlined
              // style={{
              //   color: filtered ? "#1677ff" : undefined,
              // }}
              />
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      dataIndex === "name" ? (
        searchedColumn === dataIndex ? (
          <>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              <img
                className="Image"
                style={{ width: "50px", marginRight: "11px" }}
                src={`${BACKEND_URL}/GetImage/${record?.image}`}
              />
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
              />
              {/* {text} */}
            </span>
          </>
        ) : (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 16,
              fontWeight: "600",
            }}
          >
            <img
              className="Image"
              style={{ width: "50px", marginRight: "11px" }}
              src={`${BACKEND_URL}/GetImage/${record?.image}`}
            />
            {text}
          </span>
        )
      ) : searchedColumn === dataIndex ? (
        <>
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        </>
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Quanitty",
      dataIndex: "quanitty",
      key: "quanitty",
      ...getColumnSearchProps("quanitty"),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ...getColumnSearchProps("color"),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      ...getColumnSearchProps("size"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      ...getColumnSearchProps("price"),
    },

    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    //   width: "30%",
    //   ...getColumnSearchProps("name"),
    // },
    // {
    //   title: "Age",
    //   dataIndex: "age",
    //   key: "age",
    //   width: "20%",
    //   ...getColumnSearchProps("age"),
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    //   ...getColumnSearchProps("address"),
    //   sorter: (a, b) => a.address.length - b.address.length,
    //   sortDirections: ["descend", "ascend"],
    // },
  ];
  const classes = useStyles();
  const handleSendMail = () => {
    if (selectListingData.length) {
      const config = {
        method: "post",
        url: `${BACKEND_URL}/sendEmailToCustomers`,
        data: { id: selectListingData },
        headers: {
          token: localStorage.getItem("Token"),
        },
      };
      axios(config)
        .then((res) => {
          setLoading(false);
          toast.success(`Successfully sended email to customers`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        })
        .catch((res) => {
          setLoading(false);
          toast.error(`Invalid error`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });
    } else {
      toast.error(`Please select a product to send to email`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  };
  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Send Mail To Customers"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button variant="outlined" onClick={() => handleSendMail()}>
                Send email
              </Button>
            }
          ></CardHeader>
          <TableContainer>
            <Table
              columns={columns}
              dataSource={listingData}
              rowSelection={rowSelection}
            />
          </TableContainer>
        </Card>
      </Container>
    </>
  );
};
export default withAppContext(SendMailToCustomers);
