import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import GroupAdd from "@material-ui/icons/GroupAdd";
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
import PieChart from "@material-ui/icons/PieChart";

import moment from "moment";
// core components
import CardStats from "../../components/Cards/CardStats.js";

import componentStyles from "../../assets/theme/components/header.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

const useStyles = makeStyles(componentStyles);

const Header = ({ AllUsers, Subscription, Goals, data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const Total = Subscription?.AvailableSubscriptions.reduce(
    (acc, cur) => (acc += cur.UserSubscription.length * cur.Charges),
    0
  ).toFixed(2);

  const SubscriptionSold = Subscription?.AvailableSubscriptions.reduce(
    (acc, cur) => (acc += cur.UserSubscription.length),
    0
  );
  console.log(data,"data");
  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            {data ? null : (
              <Grid container>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="Users"
                    title={AllUsers?.User ? AllUsers?.User.length : 0}
                    icon={InsertChartOutlined}
                    color="bgError"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          <Box
                            component={ArrowUpward}
                            width="1.5rem!important"
                            height="1.5rem!important"
                          />{" "}
                          {AllUsers?.User ? AllUsers?.User.length : 0}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          Users
                        </Box>
                      </>
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="Subscriptions"
                    title={
                      Subscription?.AvailableSubscriptions
                        ? Subscription?.AvailableSubscriptions.length
                        : 0
                    }
                    icon={PieChart}
                    color="bgWarning"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          <Box
                            component={ArrowUpward}
                            width="1.5rem!important"
                            height="1.5rem!important"
                          />{" "}
                          {Subscription?.AvailableSubscriptions
                            ? Subscription?.AvailableSubscriptions.length
                            : 0}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          till Today
                        </Box>
                      </>
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="Goals Created"
                    title={Goals?.Goal ? Goals?.Goal.length : 0}
                    icon={GroupAdd}
                    color="bgWarningLight"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          <Box
                            component={ArrowUpward}
                            width="1.5rem!important"
                            height="1.5rem!important"
                          />{" "}
                          {Goals?.Goal ? Goals?.Goal.length : 0}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          till Today
                        </Box>
                      </>
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                  <CardStats
                    subtitle="Subscription Sold"
                    title={`$ ${Total === undefined ? 0 : Total}`}
                    icon={EmojiEvents}
                    color="bgInfo"
                    footer={
                      <>
                        <Box
                          component="span"
                          fontSize=".875rem"
                          color={theme.palette.success.main}
                          marginRight=".5rem"
                          display="flex"
                          alignItems="center"
                        >
                          <Box
                            component={ArrowUpward}
                            width="1.5rem!important"
                            height="1.5rem!important"
                          />{" "}
                          {SubscriptionSold === undefined
                            ? 0
                            : SubscriptionSold}
                        </Box>
                        <Box component="span" whiteSpace="nowrap">
                          Sold till Today
                        </Box>
                      </>
                    }
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default withAppContext(Header);
