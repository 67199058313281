import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import "sweetalert2/src/sweetalert2.scss";
import Header from "../../components/Headers/Header.js";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";
import { Button, Input, Table } from "antd";
import { SearchOutlined } from "@material-ui/icons";

const useStyles = makeStyles(componentStyles);

const CustomerTable = () => {
  const classes = useStyles();
  const history = useHistory();
  const [AllCustomer, setAllCustomer] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  function GetAllCustomer() {
    axios
      .get(`${BACKEND_URL}/GetAllCustomer`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setAllCustomer(res.data);
      })
      .catch((res) => console.log(res));
  }
  useEffect(() => {
    GetAllCustomer();
  }, []);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          id={`search-input-${dataIndex}`}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? <span>{text}</span> : text,
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>
        a.name.localeCompare(b.name, undefined, {
          sensitivity: "base",
        }),
      ...getColumnSearchProps("name"),
      render: (text, record) => <p>{record.name || "-"}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) =>
        a.email.localeCompare(b.email, undefined, {
          sensitivity: "base",
        }),
      ...getColumnSearchProps("email"),
      render: (email) => <p>{email || "-"}</p>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      sorter: (a, b) =>
        a.phoneNumber.localeCompare(b.phoneNumber, undefined, {
          sensitivity: "base",
        }),
      ...getColumnSearchProps("phoneNumber"),
      render: (phoneNumber) => <p>{phoneNumber || "-"}</p>,
    },
    {
      title: "Sale",
      dataIndex: "CustomerSale",
      key: "CustomerSale",
      sorter: (a, b) => a?.CustomerSale?.length - b?.CustomerSale?.length,
      render: (CustomerSale) => <p>{CustomerSale?.length || 0}</p>,
    },
    {
      title: "Sale Total",
      dataIndex: "CustomerSale",
      key: "SaleTotal",
      sorter: (a, b) => {
        let sale =
          a.CustomerSale?.reduce((acc, curr) => (acc += curr.total), 0) || 0;

        let saleB =
          b.CustomerSale?.reduce((acc, curr) => (acc += curr.total), 0) || 0;
        return sale - saleB;
      },
      render: (CustomerSale) => (
        <p>
          Rs. {CustomerSale?.reduce((acc, curr) => (acc += curr.total), 0) || 0}
        </p>
      ),
    },
  ];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="All Customer"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>
          {AllCustomer?.length >= 1 && (
            <Table
              columns={columns}
              dataSource={AllCustomer}
              rowKey="id"
              style={{ paddingRight: 10, paddingLeft: 10 }}
              className="userTable"
            />
          )}
        </Card>
      </Container>
    </>
  );
};

export default withAppContext(CustomerTable);
