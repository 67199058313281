import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import "antd/dist/antd.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import AppProvider from "./contextApi/Auth";
import theme from "./assets/theme/theme.js";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

import PrivateRoute from "./contextApi/Auth/PrivateRoute";
import PurchaseProvider from "./contextApi/Purchase";
// import Router from "./router";
import FullViewLoader from "./components/Loader/fullViewLoader";

function App() {
  const Router = lazy(() => import("./router"));
  //   const [TokenAvailable, setTokenAvailable] = useState(null);
  //   useEffect(() => {
  //     let url = window.location.pathname;
  //     if (url === "/") {
  //       Redirect(TokenAvailable ? "/admin/index" : "/auth/login");
  //     }
  //   }, [TokenAvailable]);
  //   useEffect(() => {
  //     console.log("RUN");
  //     console.log(localStorage.getItem("Token"));
  //     console.log(TokenAvailable);
  //   }, [TokenAvailable,localStorage.getItem("Token")]);
  //   useEffect(() => {
  //     const token = localStorage.getItem("Token");
  //     console.log(token, "token");
  //     if (token) {
  //       setTokenAvailable(token);
  //       // Redirect(TokenAvailable ? "/admin/index" : "/auth/login");
  //     } else {
  //       setTokenAvailable(null);
  //     }
  //   }, [TokenAvailable, localStorage.getItem("Token")]);
  return (
    <AppProvider>
      <ToastContainer
        style={{
          zIndex: 9999999999999999999999999n,
        }}
      />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <PurchaseProvider>
            <Suspense fallback={<FullViewLoader />}>
              <Router />
            </Suspense>
          </PurchaseProvider>
        </BrowserRouter>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
