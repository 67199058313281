import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import "./suspenseImg.scss";
const SuspenseImg = ({ src, height, type, Infoheight, ...rest }) => {
  const [Loader, setLoader] = useState(true);
  useEffect(() => {
    // throw new Promise((resolve) => {
    if (type?.split("/")[0] === "video") {
      setLoader(false);

    } else {

      const img = new Image();
      img.onload = () => {
        // resolve();
        setTimeout(() => {
          setLoader(false);
        }, 1000);
        // console.log(img, "IMG");
      };
      img.src = src;
      return () => {
        img.onload = null;
      };
    }
    // });
  }, []);
  console.log(type?.split("/")[0], "type?.split[0]");
  return Loader ? (
    <Skeleton.Image
      // className={height ? "ImgHeight" : Infoheight ? "Infoheight" : "Height"}
      {...rest}
      active={true}
      width={"100%"}
    />
  ) : type?.split("/")[0] === "video" ? (
    <video controls={true} autoPlay={true} muted className="video-add-product">
      <source type={type} src={src}></source>
    </video>
  ) : (
    <img alt="" src={src} {...rest} />
  );
};
export default SuspenseImg;
