import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { BACKEND_URL } from "../../env";
// import { io } from "socket.io-client";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const AppContext = createContext();
export const withAppContext = (Component) => (props) =>
  (
    <AppContext.Consumer>
      {(value) => <Component {...value} {...props} />}
    </AppContext.Consumer>
  );

const AppProvider = ({ children }) => {
  const token = localStorage.getItem("Token");
  console.log(token, "token");
  const [AllCategory, setAllCategory] = useState([]);
  const [AllMultipleProducts, setAllMultipleProducts] = useState([]);
  const [GetToken, setGetToken] = useState(null);
  const [refresh, setRefresh] = useState(false);

  function GetLogin(Obj, setLoading, history) {
    const config = {
      method: "post",
      url: `${BACKEND_URL}/LoginUser`,
      data: Obj,
    };
    axios(config)
      .then((res) => {
        // console.log(res, "res");
        if (res.data.token) {
          setGetToken(res.data.token);
          console.log(res.data, "res");
          localStorage.setItem("Token", res.data.token);
          history.push("/admin/index");
          setLoading(false);
          toast.success("Login Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          // 	// Swal.fire({
          // 	// 	title: "Are you sure?",
          // 	// 	text: "Your Credintials Are not Correct!",
          // 	// 	icon: "warning",
          // 	// 	showCancelButton: true,
          // 	// 	confirmButtonColor: "#3085d6",
          // 	// 	cancelButtonColor: "#d33",
          // 	// 	confirmButtonText: "Retry"
          // 	// });
          toast.error(
            res?.data?.message ? res?.data?.message : "Invalid error",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
          setLoading(false);
        }
      })
      .catch((res) => {
        console.log(res, "res");
        Swal.fire({
          // title: "Are you sure?",
          text: "Your Credintials Are not Correct!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Retry",
        });
        setLoading(false);
      });
  }
  function GetAllCategories() {
    axios
      .get(`${BACKEND_URL}/GetAllCategories`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllCategory(res.data);
      })
      .catch((res) => console.log(res));
  }
  function GetAllMultipleProducts() {
    axios
      .get(`${BACKEND_URL}/filterProduct`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllMultipleProducts(res.data);
      })
      .catch((res) => console.log(res));
  }

  useEffect(() => {
    GetAllCategories();
  }, [!refresh]);

  const [AllProducts, setAllProducts] = useState([]);
  const [AllRatings, setAllRatings] = useState([]);
  function GetAllProducts() {
    axios
      .get(`${BACKEND_URL}/GetAllProducts`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllProducts(res.data);
      })
      .catch((res) => console.log(res));
  }
  function GetAllRatings() {
    axios
      .get(`${BACKEND_URL}/GetAllRatingWithProductDetails`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setAllRatings(res.data);
      })
      .catch((res) => console.log(res));
  }

  // useEffect(() => {
  // 	GetAllProducts();
  // }, [!refresh]);

  // const socket = io(`${BACKEND_URL}`);
  // useEffect(() => {
  //   socket.on("connect", () => {
  //     AllProducts.map((pro, i) => {
  //       socket.on(`Quantity-${pro.id}`, (msg) => {
  //         pro.QuantityAvailable = msg;
  //         AllProducts.filter((a) => {
  //           if (a.id == pro.id) {
  //             a.QuantityAvailable = msg;
  //           }
  //           setAllProducts(AllProducts);
  //         });
  //       });
  //     });
  //   });
  // }, [socket]);

  // COLOR
  const [colorData, setColorData] = useState([]);
  const [addColorLoading, setAddColorLoading] = useState([]);
  const [colorLoading, setColorLoading] = useState([]);
  function createColor(Obj, handleClose, clearState) {
    setAddColorLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/CreateColors`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getColor();
        clearState();
        setAddColorLoading(false);
      })
      .catch((res) => {
        setAddColorLoading(false);
      });
  }
  function getColor() {
    setColorLoading(true);
    axios
      .get(`${BACKEND_URL}/GetAllColors`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setColorData(data.data.data);
        setColorLoading(false);
      })
      .catch(() => {
        setColorLoading(false);
      });
  }
  function updateColor(Obj, handleClose, clearState, id) {
    setAddColorLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/UpdateColor/${id}`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getColor();
        clearState();
        setAddColorLoading(false);
      })
      .catch((res) => {
        setAddColorLoading(false);
      });
  }
  function deleteColor(id) {
    setAddColorLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/DeleteColor/${id}`,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        getColor();
      })
      .catch((res) => {});
  }

  //Tags
  const [addTagLoading, setAddTagLoading] = useState([]);
  const [TagsData, setTagsData] = useState([]);
  const [addTagsLoading, setAddTagsLoading] = useState([]);
  function getTags() {
    setAddTagsLoading(true);
    axios
      .get(`${BACKEND_URL}/getAllTags`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setTagsData(data?.data?.tags);
        setAddTagsLoading(false);
      })
      .catch(() => {
        setAddTagsLoading(false);
      });
  }
  // function updateTags(Obj, handleClose, clearState, id) {
  //   setAddTagLoading(true);
  //   const config = {
  //     method: "post",
  //     url: `${BACKEND_URL}/UpdateColor/${id}`,
  //     data: Obj,
  //     headers: {
  //       token: localStorage.getItem("Token"),
  //     },
  //   };
  //   axios(config)
  //     .then((res) => {
  //       handleClose();
  //       getColor();
  //       clearState();
  //       setAddTagLoading(false);
  //     })
  //     .catch((res) => {
  //       setAddTagLoading(false);
  //     });
  // }

  // function deleteTags(id) {
  //   setAddTagLoading(true);
  //   const config = {
  //     method: "post",
  //     url: `${BACKEND_URL}/DeleteColor/${id}`,
  //     headers: {
  //       token: localStorage.getItem("Token"),
  //     },
  //   };
  //   axios(config)
  //     .then((res) => {
  //       getColor();
  //     })
  //     .catch((res) => {});
  // }

  // Size
  const [sizeData, setSizeData] = useState([]);
  const [addSizeLoading, setAddSizeLoading] = useState([]);
  const [sizeLoading, setSizeLoading] = useState([]);
  function createSize(Obj, handleClose, clearState) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/CreateSizes`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getSize();
        clearState();
        setAddSizeLoading(false);
      })
      .catch((res) => {
        setAddSizeLoading(false);
      });
  }
  function getSize() {
    setSizeLoading(true);
    axios
      .get(`${BACKEND_URL}/GetAllSizes/`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((data) => {
        setSizeData(data.data.data);
        setSizeLoading(false);
      })
      .catch(() => {
        setSizeLoading(false);
      });
  }

  function updateSize(Obj, handleClose, clearState, id) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/UpdateSize/${id}`,
      data: Obj,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        handleClose();
        getSize();
        clearState();
        setAddSizeLoading(false);
      })
      .catch((res) => {
        setAddSizeLoading(false);
      });
  }

  function deleteSize(id) {
    setAddSizeLoading(true);
    const config = {
      method: "post",
      url: `${BACKEND_URL}/Deletesize/${id}`,
      headers: {
        token: localStorage.getItem("Token"),
      },
    };
    axios(config)
      .then((res) => {
        getSize();
      })
      .catch((res) => {});
  }
  // useEffect(() => {
  //   console.log(TagsData,"TagsDataTagsDataTagsData");
  // }, [TagsData]);
  return (
    <AppContext.Provider
      value={{
        token,
        AllCategory,
        GetAllCategories,
        GetAllMultipleProducts,
        AllMultipleProducts,
        GetAllProducts,
        GetLogin,
        GetToken,
        TagsData,
        addTagLoading,
        AllProducts,
        refresh,
        setRefresh,
        createColor,
        getColor,
        addColorLoading,
        colorLoading,
        colorData,
        addTagsLoading,
        getTags,
        updateColor,
        deleteColor,
        sizeData,
        addSizeLoading,
        sizeLoading,
        getSize,
        updateSize,
        deleteSize,
        createSize,
        GetAllRatings,
        AllRatings,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
