import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";

const useStyles = makeStyles(componentStyles);

const GuestTable = ({refresh}) => {
	const classes = useStyles();
	const history = useHistory();
	const [AllGuest, setAllGuest] = useState([]);
	function GetAllGuest() {
		axios
			.get(`${BACKEND_URL}/GetAllGuest`, {
				headers: {
					token: localStorage.getItem("Token")
				}
			})
			.then((res) => {
				setAllGuest(res.data);
			})
			.catch((res) => console.log(res));
	}
	useEffect(() => {
		GetAllGuest();
	}, [!refresh]);

	return (
		<>
			<Header />
			{/* Page content */}
			<Container
				maxWidth={false}
				component={Box}
				marginTop="-6rem"
				classes={{ root: classes.containerRoot }}
			>
				<Card classes={{ root: classes.cardRoot }}>
					<CardHeader
						className={classes.cardHeader}
						title="All Guest"
						titleTypographyProps={{
							component: Box,
							marginBottom: "0!important",
							variant: "h3"
						}}
					></CardHeader>
					{AllGuest?.length >= 1 ? (
						<TableContainer>
							<Box component={Table} alignItems="center" marginBottom="0!important">
								<TableHead>
									<TableRow>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Name
										</TableCell>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Email
										</TableCell>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Phone Number
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{AllGuest?.map((Guest) => {
										return (
											<TableRow key={Guest?.id}>
												<TableCell
													classes={{
														root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead
													}}
													component="th"
													variant="head"
													scope="row"
												>
													<Box alignItems="center" display="flex">
														<Box display="flex" alignItems="flex-start">
															<Box fontSize=".875rem" component="span">
																{Guest?.name || "-"}
															</Box>
														</Box>
													</Box>
												</TableCell>
												<TableCell classes={{ root: classes.tableCellRoot }}>
													{Guest?.email || "-"}
												</TableCell>
												<TableCell classes={{ root: classes.tableCellRoot }}>
													<Box paddingTop=".35rem" paddingBottom=".35rem">
														{Guest?.phoneNumber || "-"}
													</Box>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Box>
						</TableContainer>
					) : (
						<div
							style={{
								width: "100%",
								display: "flex",
								alignContent: "center",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<h1>No Guest to Show</h1>
						</div>
					)}
					<Box
						classes={{ root: classes.cardActionsRoot }}
						component={CardActions}
						justifyContent="flex-end"
					>
						<Pagination count={3} color="primary" variant="outlined" />
					</Box>
				</Card>
			</Container>
		</>
	);
};

export default withAppContext(GuestTable);
