import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";

const useStyles = makeStyles(componentStyles);

const PurchaseTable = ({}) => {
	const classes = useStyles();
	const history = useHistory();

	const [AllPurchase, setAllPurchase] = useState([]);
	function GetAllPurchase() {
		axios
			.get(`${BACKEND_URL}/GetAllPurchases`, {
				headers: {
					token: localStorage.getItem("Token")
				}
			})
			.then((res) => setAllPurchase(res.data))
			.catch((error) => console.log(error));
	}

	useEffect(() => {
		GetAllPurchase();
	}, []);

	return (
		<>
			<Header />
			{/* Page content */}
			<Container
				maxWidth={false}
				component={Box}
				marginTop="-6rem"
				classes={{ root: classes.containerRoot }}
			>
				<Card classes={{ root: classes.cardRoot }}>
					<CardHeader
						className={classes.cardHeader}
						title="Purchase"
						titleTypographyProps={{
							component: Box,
							marginBottom: "0!important",
							variant: "h3"
						}}
						action={
							<IconButton
								aria-label="settings"
								style={{ background: "blue" }}
								onClick={() => history.push("/admin/CreatePurchase")}
							>
								<Add style={{ width: "30px", height: "30px", color: "#FFF" }} />
							</IconButton>
						}
					></CardHeader>
					{AllPurchase?.length >= 1 ? (
						<TableContainer>
							<Box component={Table} alignItems="center" marginBottom="0!important">
								<TableHead>
									<TableRow>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Productid
										</TableCell>
										<TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Quantity
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{AllPurchase?.map((purchase) => {
										return (
											<TableRow key={purchase?.id}>
												<TableCell
													classes={{
														root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead
													}}
													component="th"
													variant="head"
													scope="row"
												>
													<Box alignItems="center" display="flex">
														<Box display="flex" alignItems="flex-start">
															<Box fontSize=".875rem" component="span">
																{purchase?.Productid || "-"}
															</Box>
														</Box>
													</Box>
												</TableCell>
												<TableCell classes={{ root: classes.tableCellRoot }}>
													{purchase?.Quantity || 0}
												</TableCell>
												<TableCell classes={{ root: classes.tableCellRoot }}>
													<Box display="flex" alignItems="center">
														<Box
															component="span"
															marginRight=".5rem"
															onClick={() =>
																Swal.fire({
																	title: "Are you sure?",
																	text: "You won't be able to revert this!",
																	icon: "warning",
																	showCancelButton: true,
																	confirmButtonColor: "#3085d6",
																	cancelButtonColor: "#d33",
																	confirmButtonText: "Yes, delete it!",
																	preConfirm: () => {
																		axios
																			.post(
																				`${BACKEND_URL}/DeletePurchase/${purchase?.id}`,
																				{},
																				{
																					headers: {
																						token: localStorage.getItem("Token")
																					}
																				}
																			)
																			.then(() => {
																				GetAllPurchase();
																			})
																			.catch((res) => console.log(res));
																	}
																}).then((result) => {
																	if (result.isConfirmed) {
																		Swal.fire(
																			"Deleted!",
																			"Your Product has been deleted.",
																			"success"
																		);
																	}
																})
															}
														>
															<Delete style={{ color: "red", width: "20px", height: "20px" }} />
														</Box>
													</Box>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Box>
						</TableContainer>
					) : (
						<div
							style={{
								width: "100%",
								display: "flex",
								alignContent: "center",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<h1>No Purchase to Show</h1>
						</div>
					)}
					<Box
						classes={{ root: classes.cardActionsRoot }}
						component={CardActions}
						justifyContent="flex-end"
					>
						<Pagination count={3} color="primary" variant="outlined" />
					</Box>
				</Card>
			</Container>
		</>
	);
};

export default withAppContext(PurchaseTable);
