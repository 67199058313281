import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components

import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import CreateUpdateSubCategory from "./CreateUpdateSubCategory";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import { BACKEND_URL } from "../../env.js";

const useStyles = makeStyles(componentStyles);

const SubCategoryTable = ({ AllCategory, GetAllCategories }) => {
  const classes = useStyles();

  const [openModel, setOpenModel] = useState(false);

  const [UpdateId, setUpdateId] = useState("");
  const [CategoryId, setCategoryId] = useState("");

  const handleClickOpen = (catId, subId) => {
    setCategoryId(catId);
    setUpdateId(subId);
    setOpenModel(true);
  };

  const handleClose = () => {
    setUpdateId("");
    setCategoryId("");
    setOpenModel(false);
  };

  useEffect(() => {
    GetAllCategories();
  }, []);
  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        {AllCategory.map((cat) => {
          return (
            <Card
              classes={{ root: classes.cardRoot }}
              key={cat?.id}
              style={{ margin: "20px 0px" }}
            >
              <CardHeader
                className={classes.cardHeader}
                title={cat?.name}
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
                action={
                  <Button
                    variant="outlined"
                    onClick={() => handleClickOpen(cat?.id)}
                  >
                    Create SubCategory
                  </Button>
                }
              ></CardHeader>
              {cat.SubCategory && cat.SubCategory.length >= 1 ? (
                <TableContainer>
                  <Box
                    component={Table}
                    alignItems="center"
                    marginBottom="0!important"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootHead,
                          }}
                        >
                          ID
                        </TableCell>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootHead,
                          }}
                        >
                          Name
                        </TableCell>

                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootHead,
                          }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cat?.SubCategory?.map((sub, i) => {
                        return (
                          <TableRow key={sub.id}>
                            <TableCell
                              classes={{
                                root:
                                  classes.tableCellRoot +
                                  " " +
                                  classes.tableCellRootBodyHead,
                              }}
                              component="th"
                              variant="head"
                              scope="row"
                            >
                              <Box alignItems="center" display="flex">
                                <Box display="flex" alignItems="flex-start">
                                  <Box fontSize=".875rem" component="span">
                                    {i + 1}
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              classes={{ root: classes.tableCellRoot }}
                            >
                              <Box paddingTop=".35rem" paddingBottom=".35rem">
                                {sub?.name}
                              </Box>
                            </TableCell>
                            <TableCell
                              classes={{ root: classes.tableCellRoot }}
                            >
                              <Box display="flex" alignItems="center">
                                <Box
                                  component="span"
                                  marginRight=".5rem"
                                  onClick={() => {
                                    handleClickOpen(cat?.id, sub?.id);
                                  }}
                                >
                                  <Edit
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </Box>
                                <Box
                                  component="span"
                                  marginRight=".5rem"
                                  onClick={() =>
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                      preConfirm: () => {
                                        axios
                                          .post(
                                            `${BACKEND_URL}/DeleteSubCategory/${sub?.id}`,
                                            {},
                                            {
                                              headers: {
                                                token:
                                                  localStorage.getItem("Token"),
                                              },
                                            }
                                          )
                                          .then((res) => {
                                            console.log(res);
                                            GetAllCategories();
                                          })
                                          .catch((res) => console.log(res));
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        Swal.fire(
                                          "Deleted!",
                                          "Your SubCategory has been deleted.",
                                          "success"
                                        );
                                      }
                                    })
                                  }
                                >
                                  <Delete
                                    style={{
                                      color: "red",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Box>
                </TableContainer>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1>No Sub Category to Show</h1>
                </div>
              )}
              <Box
                classes={{ root: classes.cardActionsRoot }}
                component={CardActions}
                justifyContent="flex-end"
              >
                <Pagination count={3} color="primary" variant="outlined" />
              </Box>
            </Card>
          );
        })}
        <CreateUpdateSubCategory
          open={openModel}
          handleClose={handleClose}
          UpdateId={UpdateId}
          CategoryId={CategoryId}
          setCategoryId={setCategoryId}
        />
      </Container>
    </>
  );
};

export default withAppContext(SubCategoryTable);
