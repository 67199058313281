import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { Table, Avatar, Button, Input } from "antd";
import Add from "@material-ui/icons/Add";
import "sweetalert2/src/sweetalert2.scss";
import Header from "../../components/Headers/Header.js";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../env.js";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { SearchOutlined } from "@ant-design/icons";

const useStyles = makeStyles(componentStyles);

const ProductsTable = ({ AllProducts, GetAllProducts }) => {
  const classes = useStyles();
  const history = useHistory();
  const [pageNo, setPageNo] = useState(1);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState(true);
  useEffect(() => {
    GetAllProducts();
  }, []);
  useEffect(() => {
    setPageNo(1);
    setPagination(true);
    setProducts(AllProducts);
  }, [AllProducts]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          id={`search-input-${dataIndex}`}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? <span>{text}</span> : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const headerStyle = {
    color: "#8898aa",
    fontSize: 12,
  };
  const columns = [
    {
      title: "",
      dataIndex: "name",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      key: "name",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 13,
            width: 80,
          }}
        >
          <Avatar
            src={
              record.Images[0]
                ? `${BACKEND_URL}/GetImage/${record.Images[0].filename}`
                : require("../../assets/img/icons/common/No Image.png").default
            }
            alt={record.name}
            style={{ marginRight: "1rem", fontSize: 13, width: 60, height: 60 }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      sorter: (a, b) =>
        a.name.localeCompare(b.name, undefined, {
          sensitivity: "base",
        }),
      ...getColumnSearchProps("name"),
      key: "name",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 13,
            width: 150,
          }}
        >
          <span>{text || "-"}</span>
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      sorter: (a, b) => a.price - b.price,

      ...getColumnSearchProps("price"),
      key: "price",
      render: (price) => (
        <p style={{ width: 100, fontSize: 13 }}>Rs.{price || 0}</p>
      ),
    },
    {
      title: "Category",
      dataIndex: "Category",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      sorter: (a, b) =>
        a.Category.localeCompare(b.Category, undefined, {
          sensitivity: "base",
        }),
      ...getColumnSearchProps("category"),
      key: "category",
      render: (Category) => (
        <p style={{ width: 100, fontSize: 13 }}>{Category || "-"}</p>
      ),
    },
    {
      title: "Sub Category",
      dataIndex: "SubCategory",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      sorter: (a, b) =>
        a.SubCategory.localeCompare(b.SubCategory, undefined, {
          sensitivity: "base",
        }),

      ...getColumnSearchProps("subCategory"),
      key: "subCategory",
      render: (SubCategory) => (
        <p style={{ width: 120, fontSize: 13 }}>{SubCategory || "-"}</p>
      ),
    },
    {
      title: "Color",
      dataIndex: "Color",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      sorter: (a, b) =>
        a.Color.localeCompare(b.Color, undefined, {
          sensitivity: "base",
        }),

      ...getColumnSearchProps("color"),
      key: "color",
      render: (Color) =>
        Color ? (
          <div
            style={{ display: "flex", fontSize: 13, width: 800, fontSize: 13 }}
          >
            {Color.split(",").map((color, index) => (
              <p key={index}>{color + ","}</p>
            ))}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Tags",
      dataIndex: "Tags",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      sorter: (a, b) =>
        a.Tags.localeCompare(b.Tags, undefined, {
          sensitivity: "base",
        }),

      ...getColumnSearchProps("tags"),
      key: "tags",
      render: (Tags) => <span style={{ fontSize: 13 }}>{Tags || "-"}</span>,
    },
    {
      title: "Initial Quantity",
      dataIndex: "initialQuantity",
      onHeaderCell: () => ({
        style: headerStyle,
      }),

      ...getColumnSearchProps("initialQuantity"),
      key: "initialQuantity",
      render: (initialQuantity) => (
        <p style={{ width: 100, fontSize: 13 }}>{initialQuantity || 0}</p>
      ),
    },
    {
      title: "Quantity Available",
      dataIndex: "QuantityAvailable",
      onHeaderCell: () => ({
        style: headerStyle,
      }),

      ...getColumnSearchProps("quantityAvailable"),
      key: "quantityAvailable",
      render: (QuantityAvailable) => (
        <p style={{ width: 120, fontSize: 13 }}>{QuantityAvailable || 0}</p>
      ),
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      onHeaderCell: () => ({
        style: headerStyle,
      }),

      ...getColumnSearchProps("discount"),
      key: "discount",
      render: (Discount) => <p style={{ fontSize: 13 }}>{Discount || 0}</p>,
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),

      ...getColumnSearchProps("createdAt"),
      key: "createdAt",
      render: (createdAt) => (
        <p style={{ fontSize: 13, width: 200 }}>{createdAt || 0}</p>
      ),
    },
    {
      title: "Actions",
      onHeaderCell: () => ({
        style: headerStyle,
      }),

      ...getColumnSearchProps("actions"),
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            icon={<EditOutlined />}
            onClick={() => history.push(`/admin/addProduct?id=${record.id}`)}
            style={{ marginRight: "8px" }}
          />
          <Button
            icon={<DeleteOutlined style={{ color: "red" }} />}
            onClick={() => handleDelete(record.id)}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Products"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <IconButton
                aria-label="settings"
                style={{ background: "blue" }}
                onClick={() => history.push("/admin/addProduct/New")}
              >
                <Add style={{ width: "30px", height: "30px", color: "#FFF" }} />
              </IconButton>
            }
          ></CardHeader>
          {products?.length >= 1 ? (
            <Table
              columns={columns}
              dataSource={products}
              rowKey="id"
              style={{ paddingRight: 10, paddingLeft: 10 }}
              className="userTable"
            />
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>No Products to Show</h1>
            </div>
          )}
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          ></Box>
        </Card>
      </Container>
    </>
  );
};

export default withAppContext(ProductsTable);
