import React from "react";
import Box from "@material-ui/core/Box";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import {
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    Paper,
    Button,
} from "@material-ui/core";
import Loader from "../../../components/Loader/loader";
function StepTwo({
    classes,
    itemsVariety,
    setItemsVariety,
    productId,
    addProductVarient,
    loading
}) {
    return (
        <Card
            classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
            }}
        >
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignitem="center"
                        justifycontent="space-between"
                    >
                        <Grid item xs="auto">
                            <Box
                                component={Typography}
                                variant="h3"
                                marginBottom="0!important"
                            >
                                Enter Variant Details
                            </Box>
                        </Grid>
                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>
            <CardContent>
                <Grid item xs={12}>
                    <FormLabel>Variants</FormLabel>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        component="th"
                                        width={300}
                                    >
                                        Variant
                                    </TableCell>
                                    <TableCell align="left" component="th">
                                        Price
                                    </TableCell>
                                    <TableCell align="left" component="th">
                                        Quantity
                                    </TableCell>
                                    <TableCell align="left" component="th">
                                        Quantity Remaining
                                    </TableCell>
                                    <TableCell align="left" component="th">
                                        Discount
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itemsVariety?.map((a, i) => {
                                    console.log(a, i, "a,i");
                                    return (
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                {a.size ? a.size + "/" : ""}
                                                {a.color}
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <Grid item > */}
                                                <FormGroup>
                                                    <FormControl
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="number"
                                                            value={a.price}
                                                            onChange={(e) => {
                                                                let obj = {
                                                                    ...a,
                                                                    price: e.target.value,
                                                                };
                                                                let arr = [...itemsVariety];
                                                                arr.splice(i, 1, obj);
                                                                setItemsVariety(arr);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                {/* </Grid> */}
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <Grid item > */}
                                                <FormGroup>
                                                    <FormControl
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="number"
                                                            value={a.quantity}
                                                            onChange={(e) => {
                                                                let obj = {
                                                                    ...a,
                                                                    quantity: e.target.value,
                                                                };
                                                                let arr = [...itemsVariety];
                                                                arr.splice(i, 1, obj);
                                                                setItemsVariety(arr);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                {/* </Grid> */}
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <Grid item > */}
                                                <FormGroup>
                                                    <FormControl
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                        marginBottom="1rem !important"
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            disabled={true}
                                                            type="number"
                                                            value={a.QuantityAvailable}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                {/* </Grid> */}
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <Grid item > */}
                                                <FormGroup>
                                                    <FormControl
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                        marginBottom="1rem !important"
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="number"
                                                            value={a.Discount}
                                                            onChange={(e) => {
                                                                let obj = {
                                                                    ...a,
                                                                    Discount: e.target.value,
                                                                };
                                                                let arr = [...itemsVariety];
                                                                arr.splice(i, 1, obj);
                                                                setItemsVariety(arr);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                {/* </Grid> */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Button
                    style={{
                        width: "100%",
                        margin: "0 1.5rem",
                        padding: "10px 20px",
                        marginBottom: "20px",
                    }}
                    variant="contained"
                    size="small"
                    className="addVariety"
                    classes={{ root: classes.buttonRootDark }}
                    onClick={() => addProductVarient()}
                >
                    {loading ? <Loader /> : productId ? "Update Product Variant & Next" : "Add Product Variant & Next"}
                </Button>
            </CardContent>
        </Card>
    )
}
export default StepTwo